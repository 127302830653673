<template>
  <div class="card">
    <img class="card-img-top" :src="eventImage" alt="Card image cap" v-if="event.event_image">
    <div class="card-body">
      <span><b>{{this.date[2]}}.{{this.date[1]}}.</b> - {{this.time}}</span>
      <h2 class="card-title fill">{{event.event_name}}</h2>
      <p class="card-text"></p>
      <a :href="event.ticket_link" class="btn btn-primary" v-if="event.ticket_link">TICKETS</a>
    </div>
  </div>
</template>

<style lang="scss">
.card {
  border: none;
  border-radius: 0;
  height: 25rem;
  width: 35rem;
  .card-body {
    position: absolute;
    border-radius: 0;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    flex-direction: column;
    color: var(--secondary);
    span {
      font-size: 2rem;
      font-weight: 300;
      b {
        font-weight: 900;
      }
    }
  }
  &:hover .card-img-top {
    filter: saturate(1) contrast(1);
  }
  .card-img-top {
    border-radius: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
    filter: saturate(0) contrast(1.1);
    transition: .3s ease;
  }
  @media (max-width: 991.98px) {
    max-width: 25rem;
    margin-right: 2.5rem !important;
    height: 20rem;
  }
  @media (max-width: 575.98px) {
    max-width: 80vw;
    height: 85vw;
    margin-right: 1rem !important;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "EventCard",
  props: ["event"],
  data() {
    return {
      eventImage: "",
      time: "",
      date: [],
      imgColors: ""
    }
  },
  async mounted() {
    console.log(this.event)
    this.prepDate()

    if (this.event.event_image) {
      try {
        const data = await axios.get("https://api.kultgleis.de/api/assets/image/" + this.event.event_image._id + "?w=600", {
          headers: {
            "api-key": "API-2000006e20d2338c73965cb4415c424271202a47"
          }
        })
        this.eventImage = data.data
        console.log(this.imgColors)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    }
  },
  methods: {
    prepDate() {
      this.time = this.event.event_time.split(" ")[1]
      this.date = this.event.event_time.split(" ")[0].split("-")
    }
  }
}
</script>