<template>
  <Navigation></Navigation>
  <div class="container-fluid">
    <router-view/>
  </div>
</template>

<style lang="scss">
* {
  --primary: #000000;
  --secondary: #fff;
  font-family: "Roboto", sans-serif;
}
html, body {
  margin: 0;
  padding: 0;
  background: var(--primary);
  color: var(--secondary);
  overflow-x: hidden;
}

h1 {
  font-weight: 900;
  font-size: 6rem;
  line-height: 4.7rem;
  letter-spacing: 4px;
  color: var(--secondary);
  &:not(.fill) {
    color: transparent !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--secondary);
  }
}

h2 {
  font-weight: 900;
  font-size: 4rem;
  line-height: 4rem;
  letter-spacing: -2px;
  color: var(--secondary);
  &:not(.fill) {
    color: transparent !important;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--secondary);
  }
}

.btn {
  border-radius: 0;
  &.btn-primary {
    background-color: #ff0 !important;
    border-color: #ff0;
    color: var(--primary);
  }
}

@media (max-width: 991.98px) {
  h1 {
    font-size: 4rem;
    line-height: 3.5rem;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 3rem;
    line-height: 2.5rem;
    letter-spacing: 1px;
  }
}

@media (max-width: 575.98px) {
  h1 {
    font-size: 3rem;
    line-height: 2.5rem;
    letter-spacing: 1px;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 2rem;
    letter-spacing: 1px;
  }
}
</style>

<script>
/* eslint-disable */
import Navigation from "@/components/Navigation.vue";

export default {
  name: "App",
  components: {
    Navigation
  },
  mounted() {
    this.$store.dispatch('getEvents')
  }
}
</script>