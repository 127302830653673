<template>
  <div class="eventsslider pb-5">
    <Flicking :options="{
      align: 'prev'
    }">
      <div class="panel" v-for="event in events" :key="event">
        <EventCard :event="event"></EventCard>
      </div>
    </Flicking>
  </div>
</template>

<style>
.panel .card {
  margin-right: 4rem;
}
.eventsslider {
  position: relative;
}
.eventsslider:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 6rem;
  z-index: 9;
  background: linear-gradient(270deg, black, transparent);
  @media (max-width: 768px) {
    width: 4rem;
  }
}
.eventsslider:hover {
  cursor: grab;
}
</style>

<script>
/* eslint-disable */
import EventCard from "@/components/EventCard.vue";
import Flicking from "@egjs/vue3-flicking";

export default {
  name: "EventSlider",
  computed: {
    events: function () {
      return this.$store.state.events
    }
  },
  components: {
    EventCard,
    Flicking
  },
  mounted() {
    this.$store.dispatch("getEvents");
  },
  setup() {
  },
}
</script>