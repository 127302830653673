<template>
  <div class="hero-video">
    <div class="hero-video__text position-absolute h-100 w-100 d-flex flex-column justify-content-center align-items-center">
      <h1 class="fill">WELCOME TO</h1>
      <img src="../assets/kultgleis_white.svg" alt="">
    </div>
    <video loop autoplay id="heroVideo" ref="heroVideo" class="w-100" muted>
      <source src="../assets/hero_video.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
export default {
  name: "HeroContainer",
  mounted() {
    this.$refs.heroVideo.play()
  }
}
</script>
<style lang="scss" scoped>
.hero-video {
  margin-left: calc(var(--bs-gutter-x) * -.5);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: url("../assets/hero_overlay.png");
    width: 40vw;
    height: 40vw;
    z-index: 999;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    transform: rotate(180deg);
    bottom: 6px;
    background: url("../assets/hero_overlay.png");
    width: 40vw;
    height: 40vw;
    z-index: 999;
    background-size: cover;
    background-repeat: no-repeat;
  }
  video {
    min-height: 25rem;
    object-fit: cover;
  }
  &__text img {
    max-width: 35rem;
    margin-top: .5rem;
    @media (max-width: 991.98px) {
      max-width: 25rem;
    }
    @media (max-width: 575.98px) {
      max-width: 17rem;
    }
  }
}
</style>