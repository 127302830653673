import { createStore } from 'vuex'
import axios from 'axios';

let apiKey = "API-2000006e20d2338c73965cb4415c424271202a47",
    host =  "https://api.kultgleis.de/api/"

export default createStore({
  state: {
    events: [
    ],
    residents: [
    ]
  },
  getters: {
    getEvents: (state) => state.events,
    getResidents: (state) => state.residents
  },
  mutations: {
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_RESIDENTS(state, residents) {
      state.residents = residents
    }
  },
  actions: {
    async getEvents({commit}) {
      try {
        const data = await axios.get(host + "content/items/Events?sort=%7B%22event_time%22%3A1%7D", {
          headers: {
            "api-key": apiKey
          }
        })
        commit("SET_EVENTS", data.data);
        console.log(data.data)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
    async getResidents({commit}) {
      try {
        const data = await axios.get(host + "content/items/Residents", {
          headers: {
            "api-key": apiKey
          }
        })
        commit("SET_RESIDENTS", data.data);
        console.log(data.data)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    }
  },
  modules: {
  }
})
