<template>
  <div class="residents_grid d-flex flex-row flex-wrap justify-content-center row">
    <div class="col-lg-4 col-sm-6" v-for="resident in residents" :key="resident">
      <ResidentCard :resident="resident"></ResidentCard>
    </div>
  </div>
</template>

<script>
import ResidentCard from "@/components/ResidentCard.vue";

export default {
  name: "ResidentsGrid",
  components: {
    ResidentCard
  },
  computed: {
    residents: function () {
      return this.$store.state.residents
    }
  },
  mounted() {
    this.$store.dispatch("getResidents");
  },
}
</script>