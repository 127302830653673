<template>
  <div class="navigation row px-3">
    <div class="col-5">
      <div class="navigation__logo">
        <svg width="1483" height="418" viewBox="0 0 1483 418" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1468.32 417.186C1476.43 417.186 1483 410.607 1483 402.491C1483 394.375 1476.43 387.796 1468.32 387.796C1460.22 387.796 1453.65 394.375 1453.65 402.491C1453.65 410.607 1460.22 417.186 1468.32 417.186Z" fill="white"/>
          <path d="M35.5198 0.549158V72.2226H83.2389V0H118.759V71.1054L100.999 89.4357L118.759 106.649V416.655H83.2389V107.785H35.5198V416.674H0V0.549158H35.5198Z" fill="white"/>
          <path d="M146.486 416.655V0H182.006V379.995H229.725V0H265.245V416.655H146.486Z" fill="white"/>
          <path d="M328.492 0V381.112H411.731V416.674H292.991V0H328.511H328.492Z" fill="white"/>
          <path d="M530.471 0V35.5622H488.861V416.674H453.341V35.5622H411.731V0H530.471Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M563.854 28.5369C580.914 9.92239 605.69 0.47341 637.541 0.47341C669.392 0.47341 694.188 9.90346 711.229 28.518C727.854 46.678 736.289 72.7337 736.289 105.986V146.964H663.339V102.104C663.339 74.9303 651.14 71.2758 639.224 71.2758C627.309 71.2758 615.11 74.9303 615.11 102.104V315.289C615.11 331.025 618.022 345.568 639.224 345.568C651.745 345.568 663.339 341.895 663.339 315.289V246.607H632.756V175.804H736.289V310.839C736.289 344.072 727.854 370.148 711.229 388.308C694.169 406.922 669.392 416.371 637.541 416.371C605.69 416.371 580.895 406.922 563.854 388.308C547.228 370.148 538.793 344.091 538.793 310.839V106.005C538.793 72.7715 547.228 46.6969 563.854 28.5369Z" fill="white"/>
          <path d="M760.631 6.04078V410.823H937.549V340.02H836.929V6.04078H760.631Z" fill="white"/>
          <path d="M1133.78 6.04078H951.867V410.823H1133.78V340.02H1028.15V235.491H1112.08V164.688H1028.15V76.862H1133.78V6.04078Z" fill="white"/>
          <path d="M1229.95 6.04078H1153.68V410.823H1229.95V6.04078Z" fill="white"/>
          <path d="M1349.7 0.47341C1286.94 0.47341 1250.95 38.5345 1250.95 104.907C1250.95 152.873 1269.37 186.124 1321.34 231.931C1359.89 265.694 1371.03 284.157 1371.03 314.191C1371.03 341.781 1359.44 345.587 1346.92 345.587C1325.71 345.587 1322.8 331.044 1322.8 315.308V281.014H1249.85V310.858C1249.85 378.915 1284.52 416.39 1347.48 416.39C1379.45 416.39 1404.41 406.903 1421.7 388.213C1438.72 369.807 1447.35 343.409 1447.35 309.741C1447.35 258.29 1429.72 225.398 1377.52 179.383C1339.48 146.055 1327.27 127.024 1327.27 101.025C1327.27 75.025 1338.33 71.3137 1350.28 71.3137C1362.24 71.3137 1373.3 75.044 1373.3 102.142V129.751H1446.25V106.043C1446.25 37.9854 1411.98 0.511277 1349.73 0.511277L1349.7 0.47341Z" fill="white"/>
        </svg>
      </div>
    </div>
    <div class="col-7">
      <ul class="navigation__items">
        <li>
          <router-link to="">Events</router-link>
        </li>
        <li>
          <router-link to="">Fotos</router-link>
        </li>
        <li>
          <router-link to="">Mieten</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.navigation {
  max-height: 4.5rem;
  background: var(--primary);
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
  box-shadow: 0 0 28px -15px var(--secondary);
  &__logo {
    svg {
      height: 3rem;
      width: auto;
    }
  }
  &__items {
    margin: 0;
    align-items: center;
    height: 100%;
    padding: 0;
    width: 100%;
    justify-content: end;
    gap: 2rem;
    list-style: none;
    display: inline-flex;
    li a {
      color: var(--secondary);
      text-decoration: none;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 200;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 0%;
        left: 0;
        bottom: 0;
        height: 1px;
        background: var(--secondary);
        transition: width .3s ease;
      }
      &:hover:after {
        width: 100%;
      }
    }
  }
}
</style>

<script>
/* eslint-disable */
export default {
  name: "Navigation"
}
</script>