<template>
  <div class="home">
    <HeroContainer></HeroContainer>
    <h1 class="py-3 pt-5">UPCOMING<br>EVENTS</h1>
    <EventsSlider></EventsSlider>
    <h1 class="py-3 pt-5 text-end">RESIDENTS</h1>
    <ResidentsGrid></ResidentsGrid>
    <ContactTeaser></ContactTeaser>
  </div>
</template>

<script>
import EventsSlider from "@/components/EventsSlider.vue";
import HeroContainer from "@/components/HeroContainer.vue";
import ResidentsGrid from "@/components/ResidentsGrid.vue";
import ContactTeaser from "@/components/ContactTeaser.vue";

export default {
  name: 'HomeView',
  components: {
    EventsSlider,
    HeroContainer,
    ResidentsGrid,
    ContactTeaser
  }
}
</script>
