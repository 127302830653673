<template>
  <div class="contact-teaser py-5 px-3 my-5">
    <div class="d-flex flex-md-row flex-column align-items-md-center gap-4">
      <div class="contact-teaser__left">
        <h1 class="mb-3">KONTAKT</h1>
        <p>E-Mail: info@kultgleis.de<br>Web: www.kultgleis.de</p>
        <b class="mt-2">Adresse</b>
        <p>DARKNET CLUB<br>Theodor-Heuss-Platz 3-4<br>49074 Osnabrück</p>
      </div>
      <div class="contact-teaser__right w-100">
        <l-map ref="map" v-model:zoom="zoom" :center="[52.27345989448689, 8.058987748078922]" class="w-100">
          <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
          >
          </l-tile-layer>
          <LMarker :lat-lng="[52.27345989448689, 8.058987748078922]"></LMarker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.contact-teaser {
  background: var(--secondary);
  margin: 0 -10px;
  h1 {
    -webkit-text-stroke-color: var(--primary) !important;
  }
  p, b {
    color: var(--primary) !important;
  }
}
.leaflet-container {
  min-height: 50vh;
  max-width: 50vw;
  @media (max-width: 767.98px) {
    max-width: 100%;
  }
  float: right;
  border-radius: 1rem;
  box-shadow: 0 4px 4px 2px rgba(0,0,0,0.1)
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%) saturate(0%);
}

.leaflet-marker-pane img.leaflet-marker-icon {
  filter: hue-rotate(206deg) brightness(1.7) !important;
}

.leaflet-marker-pane img.leaflet-marker-icon {
  filter: hue-rotate(292deg)
}
</style>

<script>
import "leaflet/dist/leaflet.css";
import {LMap, LMarker, LTileLayer} from "@vue-leaflet/vue-leaflet";
export default {
  name: "ContactTeaser",
  components: {
    LMarker,
    LMap,
    LTileLayer,
  },
  data() {
    return {
      zoom: 15,
    };
  },
}
</script>