<template>
  <div class="resident_grid__card">
    <div class="resident_grid__info">
      <h3>{{resident.name}}</h3>
      <div class="resident_grid__info__socials d-flex flex-row align-items-center gap-3">
        <div class="resident_grid__info__socials__item" v-if="resident.socials.instagram">
          <a :href="resident.socials.instagram" target="_blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
        </div>
        <div class="resident_grid__info__socials__item" v-if="resident.socials.facebook">
          <a :href="resident.socials.facebook" target="_blank">
            <i class="fa-brands fa-facebook"></i>
          </a>
        </div>
        <div class="resident_grid__info__socials__item" v-if="resident.socials.soundcloud">
          <a :href="resident.socials.soundcloud" target="_blank">
            <i class="fa-brands fa-soundcloud"></i>
          </a>
        </div>
        <div class="resident_grid__info__socials__item" v-if="resident.socials.spotify">
          <a :href="resident.socials.spotify" target="_blank">
            <i class="fa-brands fa-spotify"></i>
          </a>
        </div>
      </div>
    </div>
    <img :src="residentImg" v-if="residentImg !== ''">
  </div>
</template>

<style lang="scss" scoped>
.resident_grid__info__socials__item a {
  color: #fff;
  font-size: 1.5rem;
}
.resident_grid__card {
  width: 100%;
  height: 23rem;
  position: relative;
  &:hover img {
    filter: saturate(1);
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: saturate(0);
    transition: filter .3s ease;
  }
  .resident_grid__info {
    padding: 1rem;
    background: rgba(0,0,0,0.7);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
}
</style>

<script>
import axios from "axios";

export default {
  name: "ResidentCard",
  props: ["resident"],
  data() {
    return {
      residentImg: "",
    }
  },
  async mounted() {
    if (this.resident.image) {
      try {
        const data = await axios.get("https://api.kultgleis.de/api/assets/image/" + this.resident.image._id + "?w=600", {
          headers: {
            "api-key": "API-2000006e20d2338c73965cb4415c424271202a47"
          }
        })
        this.residentImg = data.data
      } catch (error) {
        alert(error);
        console.log(error);
      }
    }
  }
}
</script>